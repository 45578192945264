.c-popup {
	display: none;
	@include size(100vw, 100vh);
	max-width: 100vw;
	max-height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 10;
	&__content {
		@include size(100%);
		@include flex(center, center);
		animation-name: open;
		animation-duration: 0.7s;
	}
	&--show {
		display: block;
	}

	@keyframes open {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

}
