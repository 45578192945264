.c-fabric-pattern {
	@include flex(flex-start, center, column);
	margin-bottom: 1.5rem;
	&__title {
		margin-bottom: 4rem;
	}
	&-collection {
		@include flex(center, center);
		margin-bottom: 4rem;
		& > div:not(:last-child) {
			margin-right: 1.6rem;
		}
	}
	&__list {
		display: block;
		width: 84.5rem;
	}
	&__item {
		height: 0;
		padding-bottom: calc(100% - 4px);
		background-color: transparent;
		border-radius: 20px;
		cursor: pointer;
		overflow: hidden;
		position: relative;

		outline: none;
		border: 4px solid transparent;
		@include transition(0.3s $cubic);
		& img, .video-item {
			@include midImg;
			border-radius: 20px;
			outline: none;
			transform-origin: 0 0;
			@include transform(scale(1.1) translate(-50%, -50%));
			@include transition(0.3s $cubic);
			&:hover {
				transform-origin: 0 0;
				@include transform(scale(1.3) translate(-50%, -50%));
				@include transition(0.3s $cubic);
			}
		}
		&.--selected {
			border: 4px solid $primary-color;
			// box-shadow: 0px 0px 2px 4px $primary-color;
			@include transition(0.3s $cubic);
		}
		&.--no-image {
			background-color: $solid-gray-color;
		}
	}
	& .c-slider {
		padding: 2.5rem 0;
		& .swiper-wrapper {
			& .swiper-slide {
				overflow: hidden;
				border-radius: 20px;
			}
		}
	}

	&__estprice {
		@include size(100%, auto);
		@include flex(flex-start, flex-end, column);
		
		margin-bottom: 10px;
		& > p {
			&:first-child {
				@include textStyle(h2);
				font-size: 2.8rem;
			}
			&:last-child {
				@include textStyle(body);
			}

		}
	}

	@include xs {
		@include flex(flex-start, center, column);
		// margin-bottom: 10rem;
		&__title {
			margin-bottom: 2rem;
		}
		&-collection {
			@include flex(center, center);
			margin-bottom: 2rem;
			& > div:not(:last-child) {
				margin-right: 1.6rem;
			}
		}
		&__list {
			display: block;
			width: 84.5rem;
		}
		&__item {
			height: 0;
			padding-bottom: calc(100% - 2px);
			border: 2px solid transparent;
			&.--selected {
				border: 2px solid $primary-color;
			}
		}
		& .c-slider {
			padding: 2.5rem 0;
			&__content{
				width: 85%;
			}
			& .swiper-wrapper {
				min-height: 0;
			}
			&__left, &__right{
				@include size(2rem);
			}
		}
	}

	.pswp {
		&__item {
			@include flex(center, center);
		}
		&__zoom-wrap {
			position: absolute;
			width: 50%;
			max-height: 90vh;
			top: 50%;
			left: 50%;
			bottom: auto;
			right: auto;
			transform: none !important;
			transform: translate(-50%, -50%) !important;
			& > div {
				@include flex(center, center, column);
				& > img {
					max-width: 70vh;
					max-height: 70vh;
					@include xs {
						min-width: 90vw;
					}
				}
				& > button {
					margin-top: 20px;
					padding: 15px 30px;
					border-radius: 20px;
					background: $primary-color;
					color: white;
				}
			}
		}
	}
}
