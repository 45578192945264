.c-single-file-upload {
	@include size(100%, auto);

	&__wrapper {
		@include size(100%, 30.4rem);
		min-height: 30.4rem;
		padding: 2.5rem;
		background-color: white;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
		border-radius: 20px;
		&--uploaded {
			@include size(100%, auto);
			padding: 0.8rem;
		}
	}
	&__dropzone {
		@include flex(center, center, column);
		@include size(100%);
		border: 2px dashed $medium-gray-color;
		border-radius: 20px;
		outline: none;
	}
	&__icon {
		@include size(4.6rem);
		margin-bottom: 2.5rem;
	}
	&__guide {
		@include textStyle(caption);
		@include size(13.7rem, auto);
		color: $solid-black-color;
		line-height: 1.89rem;
		margin-bottom: 0.4rem;
		text-align: center;
		& > span {
			color: $primary-color;
			text-decoration: underline;
			cursor: pointer;
		}
	}
	&__support {
		@include font(fontMedium, 1.2rem);
		color: $solid-gray-color;
		line-height: 102%;
	}
	&__preview {
		@include flex(flex-start, center, column);
		@include size(100%);
	}
	&__image {
		@include size(100%, 24.9rem);
		border-radius: 20px;
		margin-bottom: 1.1rem;
	}
}
