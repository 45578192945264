.c-home-how-it-work {
	@include flex(flex-start, center, column);
	padding: 5rem 0 0;

	&__title {
		@include flex(center, center);
		padding: 0 18rem;
		margin: 3.2rem 0;
	}

	@include sm {
		padding: 2rem 0 5rem;

		&__video {
			@include size(100%, auto);
			padding: 10px;
		}

		&__title {
			width: 100%;
			padding: 0 16px;
		}
	}
}
