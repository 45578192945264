.c-small-button-1 {
	@include textStyle(btnSmall);
	@include size(100%, auto);
	min-width: 135px;
	display: inline-block;
	padding: 1rem 2.4rem;
	border: 2px solid $primary-color;
	border-radius: 36px;
	cursor: pointer;

	&__container {
		@include flex(center, baseline);
		text-align: center;
	}

	&__text {
		// min-width: 64px;
		color: $primary-color;
		text-transform: capitalize;
		transition: color 0.3s linear;
	}

	&__icon {
		@include size(1.2rem, 1rem);
		margin-left: 1rem;
		display: none;
	}

	&--active {
		background-color: $primary-color;
		& .c-small-button-1__text {
			color: white;
		}
		& .c-small-button-1__icon {
			display: inline;
		}
	}
}
