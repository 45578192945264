.tailor-login {
	@include flex(center, center, column);
	width: 90%;
	padding: 16px 40px;
	background: white;
	border-radius: 10px;
    & > h2 {
        @include font(fontSemiBold, 32px);
    }
    &__error {
        color: red;
        margin-bottom: 16px;
    }
    & > .c-text-input {
        width: 100%;
        margin-bottom: 16px;
    }
    &__btn {
        margin-bottom: 32px;
    }
    &__contact{ 
        opacity: 0.7;
    }
}
