.c-summary-content {
	padding-bottom: 10rem;
	&__title {
		@include flex(center, center);
		margin: 3.2rem 0;
	}
	&__rqmt {
		margin-bottom: 5.8rem;
	}
	&__msmt {
		margin-bottom: 5rem;
		// padding-bottom: 7rem;
	}
	&-note {
		@include flex(center, center, column);
		margin: 5rem 0;
		&__title {
			margin-bottom: 3.2em;
			& .c-title {
				@include size(84.6rem, auto);
			}
		}
		&__form {
			@include size(100%, auto);
		}
	}
	&--mobile-custom {
		display: flex;
		flex-direction: column-reverse;
	}

	@include xs {
		&-note {
			margin: 5rem 0;
			&__title {
				& .c-title {
					@include size(100%, auto);
				}
			}
			&__form {
				& .c-rqmt-note-form {
					margin-right: 0;
					margin-bottom: -1.5rem;
					&__input {
						width: 100%;
						margin-right: 0;
						margin-bottom: 1.5rem;
					}
				}
			}
		}
		&__title {
			@include flex(center, center);
			margin: 3rem 0;
		}
		&__rqmt {
			margin-bottom: 3rem;
		}
		&__msmt {
			margin-bottom: 3rem;
		}
	}
}
