.c-picker {
	@include flex(center, center);
	flex-wrap: wrap;
	max-height: 100%;
	margin-right: -1.6rem;
	margin-bottom: -1.6rem;
	&__item {
		margin-right: 1.6rem;
		margin-bottom: 1.6rem;
	}
	& .--disabled {
		opacity: 0.7 !important;
		& .c-small-button-1 {
			cursor: default;
		}
	}

	@include xs {
		
		&__item {
			margin-bottom: 1.6rem;
		}
	}
}
