.customer-fb-container {
    background-color: #FFFFFF;
    padding: 96px 80px;
    @include xs {
        padding: 64px 16px;
        .c-slider__left, .c-slider__right {
            top: 5%;
        }
    }

    .customer-fb-content {
        padding-inline: 40px;
    }

    .title {
        @include xs {
            line-height: 38px;
            font-size: 30px;
            margin-bottom: 16px;
        }

        margin-bottom: 64px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        /* identical to box height, or 122% */
        text-align: center;
        letter-spacing: -0.02em;

        /* Gray/900 */
        color: #101828;
    }

    .comment {
        margin-top: 47.5px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        /* or 122% */
        letter-spacing: -0.02em;

        @include xs {
            margin-top: 16px;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
        }
    }
}

.avt {
    width: 56px;
    height: 56px;
}

.job-address {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
}

.rating {
    margin-top: 16px;

    @include xs {
        margin-top: 8.5px;
    }
}

.customer-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}
.customer-product-image {
    @include xs {
        margin-top: 24px;
    }
}