.tailor-header {
    @include flex(flex-start, center);
    width: 100%;
    height: 60px;
    padding: 0 2.4rem;
    overflow: hidden;
    background: $primary-color;
    position: relative;
    & > i {
        & > img {
            @include size(24px);
        }
    }
    & > p {
        @include font(fontSemiBold, 20px);
        color: white;
        text-align: center;
        line-height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}