.c-admin-layout {
	@include size(100%, auto);
	padding: 2rem;
	overflow: hidden;
	&__tabs {
		@include size(100%);
		min-height: 100vh;
		background: white;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	}
	&__title {
		--beforeLeft: 0;
		@include flex();
		height: 5rem;
		position: relative;
		& > a {
			@include textStyle(h3);
			height: 100%;
			width: 50%;
			color: $light-black-color;
			padding: 1rem;
			text-align: center;
			background: $light-gray-color;
			cursor: pointer;
			text-transform: uppercase;
			transition: all 0.3s ease;
			opacity: 0.7;
			&.active {
				color: $solid-black-color;
				opacity: 1;
				background: white;
				transition: all 0.3s ease;
			}
			&:hover {
				opacity: 1;
				color: $solid-black-color;
				transition: all 0.3s ease;
			}
		}
		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	left: var(--beforeLeft);
		// 	bottom: 0;
		// 	width: 50%;
		// 	height: 3px;
		// 	background: $primary-color;
		// 	transition: all 0.3s ease-out;
		// }
	}
	&__content {
		@include size(100%, calc(100% - 5rem));
		padding: 1rem;
	}
}
