.c-msmt-offline {
	padding-bottom: 10rem;
	&__header {
		margin-bottom: 3.2em;
	}
	&__form {
		padding-bottom: 3.2rem;
	}
	&-estimated-sizes {
		@include flex(flex-start, flex-start, column);
		margin-bottom: 9rem;
		&__text {
			@include textStyle(body);
			color: $medium-black-color;
			text-align-last: left;
			margin-bottom: 1rem;
		}
	}
}
