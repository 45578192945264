.tailor-menu-page {
	@include size(100%);
	&__header {
		width: 100%;
		padding: 3.2rem 2.4rem;
		& > p {
			padding: 1.2rem;
			@include font(fontSemiBold, 24px);
			color: $primary-color;
			text-align: center;
			background-color: $subprimary-color;
			border-radius: 10px;
		}
	}
	&__list {
		@include flex();
		padding: 3.2rem 2.4rem;
		margin-right: -3rem;
		margin-bottom: -3rem;
	}
	&__item {
		@include flex(center, center, column);
		width: calc(50% - 3rem);
		margin-right: 3rem;
		margin-bottom: 3rem;
		& > i {
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			border-radius: 50%;
			background: $primary-color;
			margin-bottom: 10px;
			position: relative;
			& > img {
				@include size(50px);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: cover;
			}
		}
	}
}
