.msmt-input-container {
    &.has-error {
        .label {
            color: red !important;
        }
        input {
            border: 1px solid red !important;
        }
    }
    .input-with-suffix {
        position: relative;
        input {
            width: 100%;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            height: 44px;
            padding: 10px 75px 10px 14px;
        }
        .suffix {
            top: 0;
            right: 0;
            bottom: 0;
            padding: 10px 19px 10px 14px;
            position: absolute;
            display: flex;
            align-items: center;
            @include xs {
                font-size: 1.6rem;
            }
        }
        
    }
    .arrow-icon {
        width: 10px;
        height: 5px;
        margin-left: 9px;
    }

    .label {
        display: inline-block;
        margin-bottom: 6px;
        margin-top: 6px;
        font-weight: 500;
        font-size: 1.4rem;
        // line-height: 20px;
        color: #344054;
    }
}