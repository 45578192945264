.tailor-order {
	width: 100%;
	margin-bottom: 2.8rem;
	position: relative;
	&-top {
		@include flex();
		padding: 16px 0;
		width: 100%;

		& .left,
		.right {
			width: calc(50% - 1rem);
		}
		& .left {
			height: 0;
			padding-bottom: calc(50% + 5rem);
			background: $subprimary-color;
			margin-right: 2rem;
			border-radius: 8px;
			position: relative;
			overflow: hidden;
			& > img {
				@include size(100%);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: cover;
			}
		}

		& .right {
			height: 0;
			padding-bottom: calc(50% + 5rem);
			position: relative;
			&__wrapper {
				@include flex(center, center, column);
				width: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				& > h3 {
					@include font(fontSemiBold, 24px);
					text-transform: capitalize;
					margin-bottom: 32px;
					line-height: 130%;
				}
			}
			&__tags {
				width: 100%;
				& .tailor-round-tag {
					&:first-child {
						margin-bottom: 8px;
					}
				}
			}
		}
	}

	&-bottom {
		padding: 8px 0;
		@include flex(flex-start, center, row, wrap);
		margin-right: -2rem;
		margin-bottom: -2rem;

		& .-wrapper {
			width: calc(50% - 2rem);
			margin-right: 2rem;
			margin-bottom: 2rem;
		}
	}

	&-btn {
		display: block;
		width: 100%;
		margin-top: 1.6rem;
		padding: 18px 12px;
		background: $primary-color;
		border-radius: 5px;
		text-align: center;
		position: relative;
		& > span {
			@include font(fontSemiBold, 18px);
			text-transform: capitalize;
			color: white;
		}
		&.--disabled {
			opacity: 0.7;
		}
	}

	&::after {
		content: '';
		width: 100%;
		height: 2px;
		background: rgba(0, 0, 0, 0.1);
		position: absolute;
		bottom: 0;
	}

    &:last-child {
        &::after {
            display: none;
        }
    }
}
