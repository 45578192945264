.c-profile-content {
	padding: 3rem 3rem 6rem 3rem;
	&__header {
		@include flex(space-between, flex-start);
		margin-bottom: 4.8rem;
	}
	&-basic-info {
		@include flex(center, center);
		&__avatar {
			@include size(7.2rem);
			margin-right: 0.8rem;
			border-radius: 50%;
			background-color: $solid-gray-color;
		}
		&__text {
			@include flex(center, flex-start, column);
			@include size(auto, 100%);
		}
		&__name {
			@include textStyle(h4);
			color: $solid-black-color;
			margin-bottom: 0.4rem;
		}
		&__pw-change {
			@include font(fontMedium, 1.4rem);
			color: $light-black-color;
			text-decoration: underline;
			line-height: 135%;
			cursor: pointer;
		}
	}
	&-button {
		@include flex();
		& .c-medium-button__text {
			min-width: auto;
		}
		&__edit {
			margin-right: 1rem;
		}
	}
	&__content {
		margin-bottom: -5.3rem;
	}
	&-main-info {
		margin-bottom: 4.8rem;
		&__title {
			@include textStyle(h4);
			margin-bottom: 1.6rem;
			color: $solid-black-color;
			text-transform: capitalize;
		}
	}
}
