.c-msmt-method {
	@include flex(center, center, column);
	@include size(100%, auto);
	height: 175px;
	padding: 2rem;
	background-color: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
	border-radius: 20px;
	transition: all 0.3s ease-in-out;
	outline: none;
	cursor: pointer;
	border: 1px solid #EBEBEB;
	&:hover {
		box-shadow: 0px 0px 8px $primary-color;
	}
	&__icon {
		@include flex(center, center);
		@include size(11rem);
		background-color: $medium-gray-color;
		border-radius: 50%;
		margin-bottom: 1.5rem;
		transition: all 0.3s ease-in-out;
		& > img {
			@include size(2rem);
		}
	}
	&__name {
		@include font(fontBold, 2rem);
		color: $solid-black-color;
		text-transform: capitalize;
		text-align: center;
		margin-bottom: 1.5rem;
		transition: all 0.3s ease-in-out;
	}
	&__desc {
		@include font(semiBold, 1.4rem);
		height: 10rem;
		text-align: center;
		color: $light-black-color;
		margin-bottom: 1.5rem;
		transition: all 0.2s ease-in-out;
	}
	&__button {
		margin-top: auto;
		min-width: 210px;
		.c-small-button-1:not(.c-small-button-1--active) {
			border: 1px solid #D0D5DD;
			.c-small-button-1__text {
				color: #344054;
			}
		}
		.c-small-button-1__text {
			font-weight: 500;
		}
		
	}
	&--active {
		background-color: $subthird-color;
		border: 2px solid $primary-color;
		& .c-msmt-method {
			&__icon {
				background-color: $primary-color;
			}
			&__name,
			&__desc {
				color: $primary-color;
			}
		}
	}

	@include xs {
		@include flex(center, center, column);
		@include size(100%, auto);
		min-height: auto;
		padding: 1rem;
		&__name {
			@include font(fontBold, 1.6rem);
		}
		&__desc {
			@include font(semiBold, 1.2rem);
		}
		&__button {
			display: none;
		}
	}
}
