.c-offline-measurement {
	@include size(100%, auto);
	&__header {
		margin-bottom: 4rem;
	}
	&__desc {
		@include textStyle(h3);
		color: $medium-black-color;
		margin-bottom: 1rem;
		text-align: left;
	}
	&__size {
		@include flex();
	}
}
