.c-fabric-type {
	@include flex(center, center, column);
	// @include size(100%, auto);
	// margin-bottom: 6.1rem;
	&__content-1 {
		@include flex(center, center);
		@include size(100%, auto);
		margin-bottom: 1.5rem;
	}
	&__title {
		@include flex(center, center);
		max-width: 326px;
		width: 326px;

		h2,
		p {
			text-align: left;
			font-size: 15px;
		}
	}
	&__picker {
		margin-left: auto;
		& > div.c-picker {
			justify-content: center;
			max-width: 530px;
			.c-picker__item {
				margin-right: 12px;
				margin-bottom: 12px;
				.c-small-button-1 {
					padding: 5px 12px;
					width: 120px;
					min-width: 120px;
				}
			}
		}
	}

	&__wrapper {
		@include size(100%, auto);
		// @include flex(center, flex-start);
		@include flex(center, flex-start, column);
	}
	&__gallery {
		@include size(100%, fit-content);
		// margin-right: 1.6rem;
	}
	&__tooltip {
		@include size(100%);
		& .c-tooltip {
			height: 100%;
		}
		.c-fabric-pattern__item{
			padding-bottom: calc(133% - 4px);
		}
		.swiper-wrapper{
			max-height: 205px;
		}
	}

	@include xs {
		@include flex(center, center, column);
		&__content-1 {
			@include flex(center, center, column);
			@include size(100%, auto);
			margin-bottom: 1.5rem;
		}
		&__title {
			@include flex(center, center);
			width: 100%;
			margin: 2rem 0 2rem;
			h2,
			p {
				text-align: center;
			}
			.c-title {
				padding: 1.6rem 0;
			}
		}
		&__picker {
			margin-left: auto;
			& > div.c-picker {
				justify-content: center;
				margin-right: 0;
				max-width: 100%;
				.c-picker__item {
					margin-right: 1.2rem;
					margin-bottom: 1.2rem;
					.c-small-button-1 {
						padding: 5px 12px;
						width: 120px;
						min-width: 120px;
						span {
							font-size: 16px;
						}
					}
				}
			}
		}
		&__wrapper {
			@include size(100%, auto);
		}
		&__gallery {
			@include size(100%, fit-content);
			// margin-right: 1.6rem;
		}
		&__tooltip {
			.c-slider__left,
			.c-slider__right {
				@include size(2rem);
			}
			.c-slider__content{
				width: 85%;
			}
			.swiper-wrapper{
				max-height: 17vh;
			}
		}
	}
}
