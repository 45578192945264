.c-navbar {
	@include size(100%, auto);
	// position: fixed;
	max-width: 100vw;
	height: 6.5rem;
	max-height: 6.5rem;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	// background-color: $primary-color;
 	& > .wrapper {
		height: 100%;
	}
	&__container {
		@include flex(space-between, center);
		height: 100%;
	}
	&__logo {
		display: inline-block;
		path {
			fill: $primary-color;
		}
		// filter: invert(58%) sepia(80%) saturate(1240%) hue-rotate(341deg) brightness(99%) contrast(96%);
		@include size(18rem, 2.1rem);
	}
	&__account {
		@include flex(stretch, center);
	}
	&__sign-up {
		margin-right: 1rem;
	}
	&__languages{
		@include flex(stretch, center);
		border: 1px solid #D0D5DD;
	}
	&__right-content{
		@include flex(space-between, center);
		gap: 1rem;
	}
	
	@include xs {
		max-height: 6rem;
		height: 6rem;
		&__logo {
			@include size(15rem, 1.6rem);
		}
		&__account {
			& > .c-account-dropdown {
				@include size(3rem);
			}
			.c-medium-button__text{
				line-height: 100% !important;
				font-size: 1.5rem !important;
				min-width: 0;
				
			}
			.c-medium-button{
				padding: 0 1rem;
				@include size(auto, 3rem);
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 6px;
			}
		}
		&__right-content{
			gap: .5rem;
		}
		&__languages{
			& > .c-languages-dropdown {
				@include size(auto, 3rem);
				padding: 0 1rem;
			}
		}

	}
}
