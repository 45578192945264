.c-thank-you-popup-content {
	@include flex(center, center, column);
	@include size(50rem, 43rem);
	padding: 7rem;
	background-color: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	border-radius: 30px;
	&__icon {
		@include size(9rem);
		margin-bottom: 2rem;
	}
	&__title {
		@include font(fontSemiBold, 2.3rem);
		margin: 2rem;
		color: $solid-black-color;
	}
	&__subtitle {
		@include size(fontMedium, 1.4rem);
		margin-bottom: 5rem;
		color: $light-black-color;
		text-align: center;
	}
	&__button {
		@include flex(center, center);
		& .c-medium-button {
			width: fit-content;
			text-transform: capitalize;
		}
	}
	&__back {
		@include size(10rem, auto);
		@include font(fontSemiBold, 1.4rem);
		color: $primary-color;
		text-transform: capitalize;
	}
}
