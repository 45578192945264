.c-fabric-options {
	@include flex(center, center, column);
	@include size(100%, auto);
	padding: 2rem 0;
    margin: 5rem 0;
	border-radius: 30px;
	&__title {
		@include textStyle(h2);
		margin-bottom: 2rem;
		color: $solid-black-color;
		& > span {
			line-height: 100%;
		}
	}
	&__options {
		@include flex(center, center);
		@include size(100%, auto);
	}
	&__option {
		@include flex(center, center, column);
		@include size(calc(45% - 1.6rem), auto);
		padding: 1rem 1rem;
		min-height: 5rem;
		margin-right: 1.6rem;
		color: $primary-color;
		height: 20.4rem;
		border: 1px solid #EBEBEB;
		box-sizing: border-box;
		box-shadow: rgba(16, 24, 40, 0.1) 0px 12px 16px -4px, rgba(16, 24, 40, 0.05) 0px 4px 6px -2px;

		border-radius: 20px;
		cursor: pointer;
		@include transition($t);
		& > span {
			@include textStyle(h3);
			text-align: center;
			margin-bottom: 1rem;
		}
		& > p {
			@include textStyle(caption);
			text-align: center;
			font-size: 1.6rem;
			line-height: 2rem;
			font-family: 'SVN-Gilroy';
			color: $solid-gray-color;
			max-width: 26.7rem;
		}
		&.--active {
			border: 2px solid $primary-color;
			background: $primary-color;
			color: white;
			@include transition($t);
			& > p {
				color: white;
			}
		}
		& > img {
			height: 40px;
			width: 40px;
			margin-bottom: 1rem;
		}
	}

	@include xs {
		@include flex(center, center, column);
		@include size(100%, auto);
		padding: 2rem 0;
		background-color: white;
		border-radius: 30px;
		margin: 0;
		&__title {
			@include textStyle(h3);
			margin: 0 20px;
			margin-bottom: 20px;
			color: $solid-black-color;
			text-align: center;
		}
		&__options {
			@include flex(center, center, column);
			@include size(100%, auto);
			gap: 2rem;
		}
		&__option {
			@include flex(center, center, column);
			@include size(calc(100% - 0.4rem), auto);
			padding: 0 2rem;
			margin-right: 0.4rem;
			color: $primary-color;
			height: 11.6rem;
			border-radius: 20px;
			border: 1px solid #EBEBEB;
			box-sizing: border-box;
			box-shadow: rgba(16, 24, 40, 0.1) 0px 12px 16px -4px, rgba(16, 24, 40, 0.05) 0px 4px 6px -2px;
			cursor: pointer;
			@include transition($t);
			& > span {
				@include textStyle(h3);
				text-align: center;
				margin-bottom: 0.5rem;
				font-size: 1.4rem;
			}
			& > p {
				@include textStyle(caption);
				text-align: center;
			}
			& > img {
				display: none;
			}
		}
	}
}
