.video-item {
    display: flex;
    @media (orientation: portrait) {
        width: 90vw;
    }
    @media (orientation: landscape) {
        width: auto;
        height: 70vh;
    }
    position: relative;
    .icon-play {

        position: absolute;
        width: 36px;
        height: 36px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(195deg) brightness(102%) contrast(104%);
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    
}