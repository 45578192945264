.c-fabric-popup-content {
	@include flex(center, center, column);
	@include size(50rem, 43rem);
	padding: 2rem;
	background-color: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	border-radius: 30px;
	&__title {
		@include textStyle(h2);
		margin: 4rem;
		color: $solid-black-color;
	}
	&__subtitle {
		@include font(fontMedium, 1.4rem);
		margin-bottom: 5rem;
		color: $light-black-color;
		text-align: center;
	}
	&__options {
		@include flex(center, center);
		@include size(100%, auto);
	}
	&__option {
		@include flex(center, center, column);
		@include size(calc(45% - 1.6rem), auto);
		padding: 0 2rem;
		min-height: 20rem;
		margin-right: 1.6rem;
		color: $primary-color;

		border-radius: 20px;
		border: 2px solid $primary-color;
		cursor: pointer;
		@include transition($t);
		& > span {
			@include textStyle(h3);
			text-align: center;
			margin-bottom: 2rem;
		}
		& > p {
			@include textStyle(caption);
			text-align: center;
		}
		&:hover {
			background: $primary-color;
			color: white;
			@include transition($t);
		}
	}

	@include xs {
		@include flex(center, center, column);
		@include size(90%, 80%);
		padding: 2rem;
		background-color: white;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
		border-radius: 30px;
		&__title {
			@include textStyle(h3);
			margin: 0 20px;
			margin-bottom: 20px;
			color: $solid-black-color;
			text-align: center;
		}
		&__subtitle {
			& > p {
				@include textStyle(caption);
				color: $light-black-color;
			}
		}
		&__online,
		&__offline {
			padding: 1rem 0;
			@include flex(center, center);
			& .c-medium-button {
				width: 100%;
				text-transform: capitalize;
				padding: 1rem 1.5rem;
				&__text {
					@include textStyle(btnSmall);
				}
			}
		}
		&__back {
			margin-top: 1rem;
			@include textStyle(caption);
			color: $light-black-color;
			@include transition(0.3s);
			:hover {
				color: $primary-color;
				@include transition(0.3s);
			}
		}
		&__option {
			@include flex(center, center, column);
			@include size(calc(50% - 0.4rem), auto);
			padding: 0 2rem;
			min-height: 20rem;
			margin-right: 0.4rem;
			color: $primary-color;
	
			border-radius: 20px;
			border: 2px solid $primary-color;
			cursor: pointer;
			@include transition($t);
			& > span {
				@include textStyle(h3);
				text-align: center;
				margin-bottom: 2rem;
			}
			& > p {
				@include textStyle(caption);
				text-align: center;
			}
			&:hover {
				background: $primary-color;
				color: white;
				@include transition($t);
			}
		}
	}
}
