.c-slider {
	@include flex(space-between, center);
	@include size(100%, auto);
	position: relative;
	&__left,
	&__right {
		@include size(3.6rem, 3.6rem);
		background: transparent;
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		z-index: 2;
	}
	&__left {
		left: 0;
	}
	&__right {
		right: 0;
	}
	&__content {
		@include flex(center, center);
		@include size(100%);
		padding: 0 6rem;
		overflow: hidden;
		& .swiper-container {
			border-radius: 20px;
			min-width: 100%;
		}
		& .c-list-loader {
			height: 100%;
		}
	}
	&__mobile-btn {
		display: none;
	}

	@include xs {
		@include flex(space-between, center, column);
		@include size(100%, auto);
		position: relative;
		// &__left,
		// &__right {
		// 	display: none;
		// }
		&__content {
			@include size(100%);
			padding: 0 0;
			overflow: hidden;
			& > .swiper-container {
				width: 100vw;
			}
		}
		&__mobile-btn {
			@include flex(space-between, center);
			@include font(fontMedium, 14px);
			width: 100%;
			color: $primary-color;

			display: none;

		}
	}
}
