.c-account-content {
	@include size(calc(100% - 32rem), 100%);
	display: inline-block;
	min-height: 100vh;
	margin: 3rem 3rem 0 0;
	background-color: white;
	margin-bottom: 6.4rem;
	box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.05);
	border-radius: 5px;

	@include xs {
		@include size(100%, auto);
		display: inline-block;
		min-height: 100vh;
		margin: 0 0 0 0;
	}
}
