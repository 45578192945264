.c-fabric-type-gallery {
	@include flex(center, center);
	&__list {
		@include flex(center, center, row, wrap);
		margin-right: -1.6rem;
		margin-bottom: -1.6rem;
	}
	&__item {
		@include size(calc(16.66% - 1.6rem), auto);
		margin-right: 1.6rem;
		margin-bottom: 4rem;
	}
	&__image {
		@include size(100%, 0);
		position: relative;
		padding-bottom: 100%;

		background-color: $solid-gray-color;
		background-color: white;
		// border: 2px solid $primary-color;

		border-radius: 20px;
		cursor: pointer;
		overflow: hidden;
		& > img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include size(100%);
			z-index: 1;
		}
		&::after {
			content: '';
			@include size(100%);
			background: black;
			opacity: 0.5;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
			@include transition($t);
		}
		& > span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-transform: uppercase;
			@include font(fontSemiBold, 16px);
			color: white;
			z-index: 3;
		}

		&.--active {
			box-shadow: 0px 0px 2px 4px $primary-color;
			&::after {
				opacity: 0;
				@include transition($t);
			}
			// background-color: $primary-color;
			// & > span {
			// 	color: white;
			// }
		}
	}
	&__btn {
		@include flex(center, center);
		& .c-small-button-1 {
			width: fit-content;
			border-radius: 40px;
		}
	}

	@include xs {
		@include flex(center, center);
		&__list {
			@include flex(center, center, row, wrap);
			margin-right: -1.6rem;
			margin-bottom: -1.6rem;
			margin-bottom: 2rem;
		}
		&__item {
			@include size(calc(25% - 1.6rem), auto);
			margin-bottom: 1.6rem;
		}
		&__image {
			padding-bottom: 95%;
			& > span {
				font-size: 14px;
			}
		}
	}
}
