
.height-weight-container {
    padding-top: 30px;
    padding-bottom: 28px;
    @include xs {
        padding-bottom: 10px;
    }
    border-bottom: 1px solid #F2F4F7;
    .height-input {
        order: 1;
    }
    .weight-input {
        order: 2;
    }
    .get-your-latest-measurement-container {
        order: 3;
        @include xs {
            order: 0;
        }
    }
}

.body-metric-container {
    padding-top: 52px;
}
.get-your-latest-measurement-container {
    justify-content: flex-end;
    @include xs {
        justify-content: center;
    }
}
.get-your-latest-measurement {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 18px 10px 20.5px;
    height: 44px;
    background: #FDE7D4;
    border: 1px solid #FDE7D4;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    .download-icon {
        width: 15px;
        height: 15px;
    }

    .text {
        margin-left: 10.5px;
        color: $primary-color;
        font-size: 1.6rem;
        @include xs {
            font-size: 1.4rem;
        }
        font-weight: 500;
        line-height: 2.4rem;
    }
}


.metric-image,
.metric-description {
    margin-top: 16px;
}

.metric-image {
    aspect-ratio: 0.7;
}

.metric-description {
    color: #344054;
}

.show-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-top: 48px;
    cursor: pointer;
}
.c-slider__content .swiper-container {
    border-radius: 0;
}