.c-languages-dropdown {
	@include flex(center, center);
	@include size(4.7rem);
	background-color: white;
	height: 40px;
	width: 90px;
	border-radius: 6px;
	outline: none;
	position: relative;
	cursor: pointer;
	z-index: 10;
	&__item {
		@include flex(space-between, center);
		div {
			display: flex;
			justify-content: center;
			align-items: center;
			span {
				height: 18px;
				height: 100%;
				color: $language-color;
			}
		}
	}
	&__flag{
		margin-right: 4px;
	}
	&__chevron {
		width: 20px;
		height: 20px;
		margin-left: 4px;
	}
	img {
		width: 16px;
		height: 12px;
	}
	ul {
		@include font(fontSemiBold, 1.6rem);
		padding: 15px 0;
		width: 15rem;
		min-width: fit-content;

		position: absolute;
		top: 110%;
		right: 0;
		border-radius: 5px;
		background-color: rgba(0, 0, 0, 0.8);

		visibility: hidden;
		transition: all 0.2s;
		transform: scale(0.8) translate(20px, -30px);
		opacity: 0;

		li {
			padding: 10px 15px;
			text-align: left;
			text-transform: capitalize;
			color: white;
			background-color: transparent;
			transition: all 0.3s;
			&:hover {
				background-color: $primary-color;
				transition: all 0.3s;
			}
		}
		&.--open {
			display: block;
			visibility: visible;
			opacity: 1;
			transform: scale(1) translate(0, 0);
			transition: all 0.2s;
		}
	}

	@include xs {
		@include size(100%);
		&__item{
			div{
				span{
					display: none;
				}
			}
		}
	}
}
