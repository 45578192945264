.c-large-button {
	display: inline-block;
	padding: 1.5rem 3.2rem;
	background-color: $primary-color;
	border-radius: 10px;
	cursor: pointer;
	&__text {
		@include textStyle(btnMedium);
		color: white;
		text-align: center;
		text-transform: uppercase;
	}

	@include sm {
		display: inline-block;
		padding: 1.5rem 3.2rem;
		border-radius: 10px;
		cursor: pointer;
		&__text {
			color: white;
			text-align: center;
			text-transform: uppercase;
		}
	
	}

	@include xs {
		display: inline-block;
		padding: 1rem 2rem;
		background-color: $primary-color;
		border-radius: 10px;
		cursor: pointer;
		&__text {
			color: white;
			text-align: center;
			text-transform: uppercase;
		}
	}
}
