@keyframes ldio-qmdo9h023pb {
	0% {
		transform: translate(6.84px, 45.599999999999994px) scale(0);
	}
	25% {
		transform: translate(6.84px, 45.599999999999994px) scale(0);
	}
	50% {
		transform: translate(6.84px, 45.599999999999994px) scale(1);
	}
	75% {
		transform: translate(45.599999999999994px, 45.599999999999994px) scale(1);
	}
	100% {
		transform: translate(84.36px, 45.599999999999994px) scale(1);
	}
}
@keyframes ldio-qmdo9h023pb-r {
	0% {
		transform: translate(84.36px, 45.599999999999994px) scale(1);
	}
	100% {
		transform: translate(84.36px, 45.599999999999994px) scale(0);
	}
}
@keyframes ldio-qmdo9h023pb-c {
	0% {
		background: #e15b64;
	}
	25% {
		background: #abbd81;
	}
	50% {
		background: #f8b26a;
	}
	75% {
		background: #f47e60;
	}
	100% {
		background: #e15b64;
	}
}
.ldio-qmdo9h023pb div {
	position: absolute;
	width: 22.799999999999997px;
	height: 22.799999999999997px;
	border-radius: 50%;
	transform: translate(45.599999999999994px, 45.599999999999994px) scale(1);
	background: #e15b64;
	animation: ldio-qmdo9h023pb 1.923076923076923s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-qmdo9h023pb div:nth-child(1) {
	background: #f47e60;
	transform: translate(84.36px, 45.599999999999994px) scale(1);
	animation: ldio-qmdo9h023pb-r 0.4807692307692307s infinite cubic-bezier(0, 0.5, 0.5, 1),
		ldio-qmdo9h023pb-c 1.923076923076923s infinite step-start;
}
.ldio-qmdo9h023pb div:nth-child(2) {
	animation-delay: -0.4807692307692307s;
	background: #e15b64;
}
.ldio-qmdo9h023pb div:nth-child(3) {
	animation-delay: -0.9615384615384615s;
	background: #f47e60;
}
.ldio-qmdo9h023pb div:nth-child(4) {
	animation-delay: -1.4423076923076923s;
	background: #f8b26a;
}
.ldio-qmdo9h023pb div:nth-child(5) {
	animation-delay: -1.923076923076923s;
	background: #abbd81;
}
.loadingio-spinner-ellipsis-4gxfm8z9inx {
	width: 114px;
	height: 114px;
	display: inline-block;
	overflow: hidden;
	background: none;
}
.ldio-qmdo9h023pb {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-qmdo9h023pb div {
	box-sizing: content-box;
}
.c-page-loader {
	height: 100vh;
	@include flex(center, center);
}
