.c-multiple-file-upload {
	@include size(100%, auto);
	min-height: 100%;
	margin-bottom: 4.8rem;
	&__wrapper {
		@include size(84.7rem, 30.4rem);
		min-height: 100%;
		padding: 2.5rem;
		background-color: white;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
		border-radius: 20px;
	}
	&__dropzone {
		@include flex(center, center, column);
		@include size(100%);
		border: 2px dashed $medium-gray-color;
		border-radius: 20px;
		outline: none;
	}
	&__icon {
		@include size(4.6rem);
		margin-bottom: 2.5rem;
	}
	&__guide {
		@include textStyle(caption);
		@include size(13.7rem, auto);
		color: $solid-black-color;
		line-height: 1.89rem;
		margin-bottom: 0.4rem;
		text-align: center;
		& > span {
			color: $primary-color;
			text-decoration: underline;
			cursor: pointer;
		}
	}
	&__support {
		@include font(fontMedium, 1.2rem);
		color: $solid-gray-color;
		line-height: 102%;
	}
	&-preview {
		&__list {
			@include flex(flex-start, center, row, wrap);
			margin-top: 1.6rem;
			margin-right: -1.6rem;
		}
		&__item {
			@include flex(center, center, column);
			@include size(calc(20% - 1.6rem), auto);
			padding: 0.8rem;
			margin-right: 1.6rem;
			background: #ffffff;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
			border-radius: 20px;
			& > img, .video-item {
				@include size(100%, 23rem);
				border-radius: 20px;
				margin-bottom: 1.1rem;
			}
		}
	}


	&__small {
		margin-bottom: 0;
		img {
			height: 40px !important;
			width: 40px !important;
		}
	}
	&__wrapper__small {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
	&-preview {
		&__list {
			&__small {
				display: none;
			}
		}
	}

	@include xs {
		@include size(100%, auto);
		&__wrapper {
			@include size(100%, 30.4rem);
		}
		&-preview {
			&__list {
				@include flex(flex-start, center, row, wrap);
				margin-top: 1.6rem;
				margin-right: -1.6rem;
			}
			&__item {
				@include flex(center, center, column);
				@include size(calc(50% - 1.6rem), auto);
				padding: 0.8rem;
				margin-right: 1.6rem;
				margin-bottom: 1.6rem;
				background: #ffffff;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
				border-radius: 20px;
				& > img {
					@include size(100%, 23rem);
					border-radius: 20px;
					margin-bottom: 1.1rem;
				}
			}
		}
	}
	
}
