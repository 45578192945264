@keyframes ldio-jgerprx9hhj {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.ldio-jgerprx9hhj div {
	position: absolute;
	animation: ldio-jgerprx9hhj 1s linear infinite;
	width: 80px;
	height: 80px;
	top: 10px;
	left: 10px;
	border-radius: 50%;
	box-shadow: 0 2px 0 0 #ff8445;
	transform-origin: 40px 41px;
}
.loadingio-spinner-eclipse-elgy90wz53 {
	width: 94px;
	height: 94px;
	display: inline-block;
	overflow: hidden;
	background: transparent;
}
.ldio-jgerprx9hhj {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.94);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-jgerprx9hhj div {
	box-sizing: content-box;
}
.c-list-loader {
	width: 100%;
	height: 80vh;
	@include flex(center, center);
}
