.c-account-msmt-content {
	padding: 3rem;
	&-header {
		@include flex(space-between, flex-start);
		margin-bottom: 3.2rem;
		&__left {
			@include flex(flex-start, flex-start, column);
		}
		&__title {
			@include textStyle(h2);
			margin-bottom: 0.8rem;
			color: $solid-black-color;
		}
		&__subtitle {
			@include textStyle(body);
			color: $solid-gray-color;
		}
		&__ytb-link {
			@include textStyle(body);
			color: $primary-color;
		}
		&__right {
			@include flex();
			& .c-medium-button__text {
				min-width: auto;
			}
		}
		&__edit {
			margin-right: 1rem;
		}
	}
	&-container {
		&-input {
			@include flex(flex-start, flex-start, row, wrap);
			margin-bottom: 6rem;
			&__item {
				@include size(calc(33.33% - 2rem), auto);
				margin-right: 2rem;
				margin-bottom: 4rem;
			}
		}
		&-standard {
			margin-bottom: 3.6rem;
			&__title {
				@include textStyle(body);
				color: $solid-black-color;
				margin-bottom: 3rem;
			}
			& .c-picker {
				margin: 0;
				justify-content: space-between;
				&__item {
					width: calc(16.66% - 1.7rem);
					margin: 0;
				}
			}
		}
		&__tooltip {
			margin-bottom: 5rem;
		}
		&-image {
			margin-bottom: 5rem;
			&__title {
				@include font(fontBold, 2.4rem);
				margin-bottom: 1rem;
				color: $solid-black-color;
			}
			&__subtitle {
				color: $medium-black-color;
			}
			&__upload {
				@include flex(space-between, center);
				margin-top: 2rem;
				margin-right: -2rem;
				cursor: pointer;
			}
			&__input {
				@include size(calc(33.33% - 2rem), auto);
				margin-right: 1.7rem;
				background: white;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
				border-radius: 20px;
			}
		}
	}
}
