.c-msmt-form {
	@include flex(flex-start, flex-start, column);
	&__title {
		@include size(100%, auto);
		@include flex(space-between, center);
		@include textStyle(h3);
		color: $solid-black-color;
		margin-bottom: 2.4rem;

		&-sync {
			@include textStyle(caption);
			color: $primary-color;
			text-decoration: underline;
			cursor: pointer;
		}
	}
	&__list {
		@include flex(flex-start, flex-start, row, wrap);
		margin-right: -2rem;
		margin-bottom: -4rem;
	}
	&__item {
		width: calc(25% - 2rem);
		margin-right: 2rem;
		margin-bottom: 4rem;
	}
	&__btn-left,
	&__btn-right {
		@include size(3.6rem, 3.6rem);
	}
	&__display-one-item {
		width: 100%;
	}
	// &__item-hidden {
	// 	display: none;
	// }

	@include xs {
		@include flex(flex-start, flex-start, column);
		&__title {
			&-sync {
				font-size: 1.2rem;
			}
		}
		&__list {
			@include flex(flex-start, flex-start, row, wrap);
			margin-right: -1rem;
			margin-bottom: -2rem;
		}
		&__item {
			@include flex(flex-start, flex-end);
			width: calc(33.33% - 1rem);
			min-height: 10rem;
			margin-right: 1rem;
			margin-bottom: 2rem;
		}
	}
}
