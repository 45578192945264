.c-msmt-standard-size {
	@include flex(center, flex-start, column);
	padding-bottom: 7rem;
	&__title {
		@include textStyle(h3);
		color: $solid-black-color;
		text-align: center;
		text-transform: capitalize;
		margin-bottom: 2.4rem;
	}
	&__selection {
		margin-bottom: 4.8rem;
	}
	&__image {
		@include size(100%, auto);
		background-color: $solid-gray-color;
		margin-bottom: 6.4rem;
	}
}
