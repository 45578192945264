.c-language-switcher {
	// position: absolute;
    // right: 0;
    // bottom: 0;
	&__container {
		display: flex;
		column-gap: 1rem;
		button {
			cursor: pointer;
			padding: 1rem;
		}
		button.selected {
			font-weight: bold;
		}
	}
}
