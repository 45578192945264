.msmt-body-container,  .msmt-product-container{
    padding: 32px;
    @include xs {
        padding: 0;
        margin-top: 80px;
        border: none;
        box-shadow: none;
    }
    border: 1px solid #EAECF0;
    /* Shadow/lg */
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 24px;
}
.msmt-category {
    font-weight: 600;
    font-size: 2.4rem;
    @include xs {
        font-size: 2rem;
    }
    color: #101828;
}
.msmt-progress {
    width: 267px;
    height: 8px;
    background: #039855;
    border-radius: 4px;
}