.c-rqmt-footer {
	@include flex(space-between, center);
	padding-bottom: 5rem;
	&__text {
		@include textStyle(caption);
		max-width: 32.4rem;
		line-height: 135%;
		color: $light-black-color;
	}

	&__btn {
		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	@include xs {
		&__text {
			font-size: 1.3rem;
			padding-right: 10%;
		}
	}
}
