.c-tailor-offer {
	@include flex(flex-start, flex-start, column);
	&.--no-offer {
		width: 100%;
	}
	&__title-list-offer{
		font-size: 1.6rem;
		font-weight: 600;
		color: #828282;
		margin-bottom: 1.43rem;
	}
	&__annouce {
		@include size(100%);
		@include textStyle(h4);
		padding: 5rem 8rem;
		border-radius: 20px;
		border: 2px dashed $primary-color;
		text-align: center;
		background: $subprimary-color;
		color: $primary-color;
		& > strong {
			font-weight: bold;
		}
	}
	&__item {
		@include flex(space-between, center);
		@include size(100%, auto);
		padding: 2rem;
		margin-bottom: 1.42rem;
		background-color: #FFFDFD;
		border-radius: 12px;
		border: 1px solid #E0E0E0;
		&--active{
			background: #FFEBD8;
			border: 1px solid #F68729;
		}
	}
	&__index {
		@include flex(center, center);
		@include size(2.4rem);
		@include font(fontBold, 1.1rem);
		border-radius: 50%;
		background-color: $solid-gray-color;
		color: $light-black-color;
	}
	&__avatar {
		@include size(4.18rem);
		border-radius: 50%;
		background-color: $solid-gray-color;
		a{
			border-radius: inherit;
			img{
				border-radius: inherit;
			}
		}
	}
	&-tailor {
		@include flex(center, flex-start, column);
		@include size(11.4rem, auto);
		&__name {
			@include font(fontSemiBold, 1.6rem);
			color: $solid-black-color;
			margin-bottom: 0.2rem;
		}
	}
	&-estimate {
		@include flex(center, center);
		@include size(19rem, auto);
		@include font(fontSemiBold, 1.4rem);
		color: $secondary-color;
		&__title {
			margin-right: 1rem;
		}
		&__value {
			padding: 0.5rem;
			background: #d5f2ea;
			border-radius: 4px;
		}
	}
	&__price {
		@include size(12.5rem, auto);
		@include font(fontSemiBold, 1.8rem);
		color: $medium-black-color;
	}
	&__button {
		@include size(10.7rem, auto);
		& > div {
			text-transform: capitalize;
			min-width: 0;
			.c-medium-button__text{
				min-width: 0;
				font-size: 13px;
				line-height: 100%;
			}
			.c-small-button-1__text{
				min-width: 0;
				font-size: 13px;
				line-height: 100%;
			}
		}
	}

	@include xs {
		width: 100%;
		@include flex(flex-start, flex-start, row, wrap);
		overflow: hidden;
		margin-right: -0.5rem;
		margin-bottom: -0.5rem;
		padding: 1rem;
		&__title-list-offer{
			width: 100%;
		}
		&__annouce {
			padding: 1rem 4rem;
			border-radius: 20px;
			border: 2px dashed $primary-color;
			text-align: center;
		}
		&__item {
			@include size(calc(50% - 0.5rem), auto);
			@include flex(space-between, center, column);
			border-radius: 20px;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
			padding: 1rem;
			margin-right: 0.5rem;
			margin-bottom: 0.5rem;
		}
		&__index {
			display: none;
		}
		&-tailor {
			@include flex(center, center, column);
			@include size(100%, auto);
			margin-bottom: 1rem;
			&__name {
				@include size(100%, auto);
				text-align: center;
			}
			& > .c-star {
				width: 100%;
				@include flex(center, center);
				margin-right: -0.6rem;
			}
		}
		&-estimate {
			@include flex(center, center);
			@include size(100%, auto);
			@include font(fontSemiBold, 1.4rem);
			color: $secondary-color;
			margin-bottom: 1rem;
			&__title {
				margin-right: 1rem;
			}
			&__value {
				padding: 0.5rem;
				background: #d5f2ea;
				border-radius: 4px;
			}
		}
		&__price {
			@include size(100%, auto);
			@include font(fontSemiBold, 1.8rem);
			color: $medium-black-color;
			text-align: center;
			margin-bottom: 1rem;
		}
		&__button {
			@include size(100%, auto);
			& > div {
				text-transform: capitalize;
				
			}
			.c-medium-button{
				padding: 1rem 2.4rem;
			}
		}
	}
}
