.c-admin-manual-offer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 80%;
	max-height: 90%;
	padding: 20px;

	border-radius: 15px;
	background: $main-bg-color;
	overflow: hidden;
	& > p {
		@include textStyle(h3);
		text-align: center;
		margin-bottom: 50px;
	}
	&__input {
		@include size(100%, auto);
		margin-bottom: 20px;
		& > p {
			@include textStyle(body);
		}
		& .--wrapper {
			@include flex(flex-start, flex-start);
			margin-right: -20px;
			margin-bottom: -20px;
			.c-text-input {
				@include size(calc(25% - 20px), auto);
				margin-right: 20px;
				margin-bottom: 20px;
				&__field {
					height: 3rem;
				}
			}
			& > .MuiFormControl-root {
				@include size(calc(25% - 20px), auto);
				min-width: auto;
				margin: none;
				margin-top: 0;
				margin-left: 0;
				margin-right: 20px;
				margin-bottom: 20px;
				& > label {
					@include font(fontMedium, 22px);
					font-family: SVN-GilroyMedium !important;
					text-transform: capitalize;
					color: $light-black-color;
					line-height: 100%;
				}
				& > .MuiInput-formControl {
					margin-top: 35px;
				}
				.MuiSelect-select {
					@include font(fontMedium, 16px);
					font-family: SVN-GilroyMedium !important;
					color: $primary-color;
				}
			}
		}
	}
	&__btn {
		display: block;
		margin-top: 50px;
		@include flex(space-between, center);
	}
}
