.tailor-mgmt-menu {
    @include flex(center, center, column);
    width: 100%;
    padding: 32px 0;
    &__item {
        width: 100%;
        padding: 16px 24px;
        margin-bottom: 24px;
        & .wrapper {
            @include flex(space-between, center);
            width: 100%;
            padding-bottom: 16px;
            position: relative;
            & > span {
                @include font(fontSemiBold, 18px);
                color: $medium-black-color;
            }
            & > i {
                @include size(24px);
            }
            &::after{
                content: '';
                width: 100%;
                height: 1px;
                background: rgba(0, 0, 0, 0.2);
                position: absolute;
                bottom: 0;
            }
        }
    }   
}