.c-tooltip {
	padding: 2rem;
	background-color: white;
	color: $medium-black-color;
	border: 1px solid $medium-gray-color;
	border-radius: 10px;
	margin-bottom: -1.5rem;
	&__title {
		@include textStyle(h4);
		margin-bottom: 1.8rem;
		text-transform: uppercase;
	}
	&__content {
		@include textStyle(caption);
		font-size: 1.4rem;
	}
	&__label {
		@include textStyle(h4);
		font-size: 1.6rem;
		color: $light-black-color;
	}
	&__value {
		color: $black-color-33;
		// width: 60%;
	}
	&__item {
		margin-bottom: 2rem;
	}
	&__item-text {
		@include flex(left, center);
		gap: 2rem;
		width: 100%;
		p {
			width: 100px;
		}
	}
	&__slider {
		margin-top: 1.2rem;
	}
	@include xs{
		&__item-text{
			p{
				width: 40%;
			}
		}
		&__value{
			width: 60%;
		}
	}
}
