.c-order-confirm-content {
	@include flex(center, center, column);
	@include size(50rem, auto);
	padding: 7rem;
	background-color: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	border-radius: 30px;
	&__icon {
		@include size(9rem);
		margin-bottom: 2rem;
	}
	&__title {
		@include font(fontSemiBold, 2.3rem);
		margin: 2rem 0;
		color: $solid-black-color;
		text-align: center;
	}
	&__subtitle {
		@include size(fontMedium, 1.4rem);
		margin-bottom: 5rem;
		color: $light-black-color;
		text-align: center;
		& strong {
			font-weight: bold;
		}
	}
	&__button {
		@include flex(space-between, center);
		width: 100%;
		& .c-medium-button {
			width: fit-content;
			text-transform: capitalize;
		}
	}
	&__phone {
		margin-bottom: 50px;
		& > .error {
			display: none;
			@include textStyle(caption);
			color: red;
			margin-top: 5px;
			&.--active {
				display: block;
			}
		}
	}
	&__back {
		@include size(10rem, auto);
		@include font(fontSemiBold, 1.4rem);
		color: $primary-color;
		text-transform: capitalize;
	}
	&__next {
		@include size(10rem, auto);
	}

	@include xs {
		@include flex(center, center, column);
		@include size(90%, auto);
		max-height: 80%;
		padding: 2rem 1rem;
		background-color: white;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
		border-radius: 30px;
		&__icon {
			@include size(30%);
			margin-bottom: 2rem;
		}
		&__title {
			@include font(fontSemiBold, 2rem);
			margin: 2rem;
			color: $solid-black-color;
		}
		&__subtitle {
			@include size(fontMedium, 1.2rem);
			margin-bottom: 5rem;
			color: $light-black-color;
			text-align: center;
		}
		&__button {
			@include flex(space-between, center);
			& .c-medium-button {
				width: 100%;
				text-transform: capitalize;
				& > p {
					min-width: unset;
				}
			}
		}
		&__phone {
			margin-bottom: 50px;
			& > .error {
				display: none;
				@include textStyle(caption);
				color: red;
				margin-top: 5px;
				&.--active {
					display: block;
				}
			}
		}
		&__back {
			@include size(12rem, auto);
			@include font(fontSemiBold, 1.4rem);
			color: $primary-color;
			text-transform: capitalize;
		}
		&__next {
			@include size(12rem, auto);
		}
	}
}
