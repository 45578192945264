.c-home-banner {
	@include size(100vw, auto);
	padding: 16px 25px;
	& > img {
		@include size(100%);
		object-fit: cover;
		border-radius: 15px;
	}

	@include sm {
		@include size(100vw, auto);
		padding: 10px 10px;
		& > img {
			height: auto;
		}
	}
}
