.c-msmt-online {
	padding-bottom: 9rem;
	&-guideline {
		margin-bottom: 1rem;
		&-top {
			@include size(53%, auto);
			margin: 0 auto;
			padding-bottom: 1.6rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			&__button-view {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: baseline;
				flex-wrap: nowrap;
				text-align: center;
				background: #ebebeb;
				border-radius: 10px;
				padding: 1.2rem 1.6rem;
				cursor: pointer;
				&__text {
					font-size: 1.4rem;
					font-weight: 600;
					line-height: 1.7rem;
					margin-right: 1rem;
				}
			}
			&__icon {
				width: 1.5rem;
				height: 1rem;
			}
			&__right {
				span {
					font-size: 1.4rem;
					font-weight: 600;
					color: $primary-color;
					text-decoration-line: underline;
					cursor: pointer;
				}
				&--mobile {
					display: none;
				}
				&--disable {
					color: #bdbdbd !important;
					cursor: not-allowed !important;
					text-decoration-line: none !important;
				}
			}
		}
		&-slider {
			@include flex(center, center);
			margin-bottom: 2rem;
			&__item {
				@include size(53%, auto);
				margin: 0 5.1rem;
				// background-color: $subthird-color;
				border-radius: 20px;
				overflow: hidden;
				img {
					min-height: 540px;
					background-color: #bdbdbd;
				}
			}
			&--custom {
				width: 53%;
				margin: 0 auto;
				margin-top: 1.6rem;
				.c-msmt-online-guideline-slider__item {
					border-radius: 0;
					width: 40%;
				}
				.c-slider__content .swiper-container {
					border-radius: 0;
				}
			}
			&__input {
				border: 2px solid #f68729;
				display: flex;
				justify-content: space-between;
				padding: 1.1rem 1.8rem;
				background-color: white;
				border-radius: 12px;
				input {
					border: none;
					outline: none;
					font-size: 1.4rem;
					font-family: SVN-GilroyMedium, sans-serif;
					width: 80%;
				}
				span {
					color: #e1e1e1;
				}
			}
		}
		&-error-input {
			min-height: 19px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 5.6rem;
			color: red;
		}
		&-bottom {
			@include flex(center, center);
			padding: 0 8.2rem;
			&__count {
				@include font(fontSemiBold, 1.8rem);
				color: $solid-black-color;
				text-transform: capitalize;
			}
		}
	}
	&-input {
		// temp hide
		// height: 1px;
		// opacity: 0;
		margin-bottom: 6.4rem;
		overflow: hidden;
	}

	@include xs {
		padding-bottom: 9rem;
		&-guideline {
			margin-bottom: 1rem;
			&-top {
				width: 100%;
				&__right {
					&--des {
						display: none;
					}
					&--mobile {
						display: block;
						text-align: right;
					}
				}
			}
			&-slider {
				@include flex(center, center);
				margin-bottom: 2rem;
				&__item {
					@include size(100%, auto);
					margin: 0 0;
					background-color: $subthird-color;
					img {
						min-height: 51vh;
					}
				}
				&--custom {
					width: 100%;
					.c-msmt-online-guideline-slider__item {
						width: 43%;
					}
				}
			}
			&-bottom {
				@include flex(center, center);
				padding: 0 0;
				text-align: center;
				&__count {
					@include font(fontSemiBold, 1.8rem);
					color: $solid-black-color;
				}
			}
		}
		&-input {
			margin-bottom: 6.4rem;
		}
	}
}
