.c-list-of-msmt-dropdown {
	position: relative;
	cursor: pointer;
	&__wrapper {
		@include flex(center, center);
		padding: 1rem 2rem;
		margin-bottom: 1rem;
		border: 2px solid $primary-color;
		border-radius: 10px;
		color: $primary-color;
		transition: all 0.4s ease;
	}
	&__text {
		@include font(fontSemiBold, 1.4rem);
		margin-right: 4.5rem;
	}
	&__icon {
		@include size(1.2rem, 1rem);
		transition: all 0.4s ease;
	}
	&__list {
		display: none;
		width: 100%;
		max-height: 15rem;
		padding: 2rem;
		overflow-y: scroll;
		margin-bottom: -1rem;

		background: #ffffff;
		border: 2px solid $primary-color;
		border-radius: 10px;

		position: absolute;
		top: 100%;
		left: 0;

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			margin: 10px;
		}

		&::-webkit-scrollbar {
			width: 12px;
		}

		&::-webkit-scrollbar-thumb {
			border: 4px solid rgba(0, 0, 0, 0);
			background-clip: padding-box;
			border-radius: 7px;
			background-color: $subprimary-color;
		}
	}
	&__item {
		@include font(fontSemiBold, 1.3rem);
		color: $medium-black-color;
		padding: 0.5rem 1rem;
		margin-bottom: 1rem;
		cursor: pointer;
		text-transform: capitalize;
		&:hover,
		&--active {
			color: $primary-color;
			padding: 1rem 1rem;
			background-color: $subthird-color;
			border-radius: 4px;
		}
	}
	&:hover {
		& > div {
			color: white;
			background-color: $primary-color;
			& > img {
				filter: invert(0) sepia(0) saturate(0) hue-rotate(0) brightness(2);
			}
		}
		& > ul {
			display: block;
		}
	}
}
