.c-order-detail {
	padding: 3rem;
	margin-bottom: 5.4rem;
	&__header {
		@include flex(flex-start, center);
		margin-bottom: 2.84rem;
	}
	&__title {
		@include font(fontBold, 3.6rem);
		margin-left: 2.7rem;
	}
	&__offer {
		margin-bottom: 3.2rem;
	}
	&-bill{
		margin-bottom: 1.6rem;
		border: 1px solid #E0E0E0;
		border-radius: 12px;
		padding: 2.5rem 3rem;
		&__item{
			margin-bottom: 1.78rem;
			display: flex;
			justify-content: space-between;
			&:nth-last-child(1){
				margin-bottom: 0;
			}
			span{
				&:nth-child(1){
					font-size: 1.42rem;
				}
				&:nth-child(2){
					font-weight: 600;
					font-size: 1.6rem;
				}
			}
		}
	}
	&-summary {
		margin-bottom: 1.5rem;
		&__rqmt {
			margin: 2.4rem 0;
			& > .c-rqmt-sum {
				padding: 0;
			}
		}
		&__msmt {
			margin-bottom: 3.65rem;
		}
		&__note {
			&-title {
				@include textStyle(h3);
				margin-bottom: 2.4rem;
				color: $medium-black-color;
			}
		}
	}
	&-shipping-info {
		margin-bottom: 5rem;
		&__form {
			padding: 2.4rem 0 3.2rem;
		}
		&__payment {
			display: none;
		}
	}
	&__button {
		@include flex(space-between, center);
		& .-wrapper {
			& .c-medium-button {
				width: fit-content;
				text-transform: capitalize;
			}
			&:first-child {
				& .c-medium-button {
					border: none;
					padding: 0;
					text-decoration: underline;
				}
			}
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	@include xs {
		padding: 2rem;
		margin-bottom: 5rem;
		&__header {
			@include flex(flex-start, center);
			margin-bottom: 3.2rem;
		}
		&__title {
			// width: 100%;
			margin-left: 0;
			@include font(fontBold, 2.14rem);
			text-align: center;
			padding-left: 1.6rem;
		}
		&__offer {
			@include flex();
			margin-bottom: 5rem;
			margin-right: -0.4rem;
		}
		&-summary {
			margin-bottom: 1.5rem;
			&__rqmt {
				margin: 2.4rem 0;
				& > .c-rqmt-sum {
					padding: 0;
				}
			}
			&__msmt {
				margin-bottom: 3.65rem;
			}
			&__note {
				&-title {
					@include textStyle(h3);
					margin-bottom: 2.4rem;
					color: $medium-black-color;
				}
			}
		}
		&-shipping-info {
			margin-bottom: 5rem;
			&__form {
				padding: 2.4rem 0 3.2rem;
			}
		}
		&__button {
			@include flex(space-between, center);
			& > div {
				width: fit-content;
				text-transform: capitalize;
			}
		}
	}
}
