.c-footer {
	@include flex(flex-start, center, column);
	@include size(100%, 24rem);
	min-height: 24rem;
	padding: 3rem 0;
	background-color: $primary-color;

	&__menu {
		margin-right: -2.5rem;
		margin-bottom: 3rem;
	}
	&__item {
		display: inline;
		@include textStyle(h4);
		margin-right: 2.5rem;
		line-height: 102%;
		color: $main-bg-color;
	}

	&__social-media {
		@include flex(center, center);
		margin-bottom: 3.4rem;

		& a:first-child {
			@include size(3.3rem);
		}
		& a:nth-child(2) {
			@include size(3.6rem);
			margin: 0 3.4rem;
		}
		& a:last-child {
			@include size(3.7rem, 2.6rem);
		}
	}

	&__business-code {
		@include flex(flex-start, center, column);
		color: $main-bg-color;
		& span:first-child {
			@include textStyle(h4);
			margin-bottom: 1rem;
			line-height: 102%;
		}
	}

	@include xs {
		overflow: hidden;
		&__menu {
			margin-right: 0rem;
			margin-bottom: 3rem;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
		&__item {
			font-size: 1.4rem;
			margin-right: 1.6rem;
			&--VN{
				margin-right: 0;
				margin-top: 1rem;
			}
		}

		&__social-media {
			@include flex(center, center);
			margin-bottom: 3rem;

			& a:first-child {
				@include size(3rem);
			}
			& a:nth-child(2) {
				@include size(3.1rem);
				margin: 0 3.4rem;
			}
			& a:last-child {
				@include size(3.4rem, 2.3rem);
			}
		}

		&__business-code {
			padding: 0 15%;
			& span {
				font-size: 1.4rem;
				text-align: center;
			}
			& span:first-child {
				font-size: 1.6rem;
			}
		}
	}
}
