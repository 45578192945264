.c-tailor-profile-speciality {
	& > .container {
		@include flex(center, center, column);
		& > h4 {
			@include font(fontSemiBold, 24px);
			margin-bottom: 16px;
		}
	}
	&__list {
		@include flex(center, center, row, wrap);
		margin-right: -8px;
		margin-bottom: -8px;
	}
	&__item {
		width: 120px;
		margin-right: 8px;
		margin-bottom: 8px;
		& > .c-small-button-2 {
			font-size: 18px;
			width: 100%;
			padding: 12px 8px;
			border-radius: 30px;
			background: #ffebd8;
			border: 2px solid #f68729;
		}
	}

	@include xs {
		& > .container {
			& > h4 {
				@include font(fontSemiBold, 18px);
			}
		}
		&__item {
			width: 100px;
		}
	}
}
